<script setup>
/* Imports */
import {
  computed,
  inject,
  ref,
  watch,
  onBeforeMount,
  onUpdated,
  onMounted,
} from 'vue';
import debounce from 'debounce';
import { Icon } from 'leaflet';
import * as L from 'leaflet';

/* Helpers */
import {
  getLatLngFromURL,
  garminTileTokenIntervalDelay,
  navionicsTileTokenIntervalDelay,
  isProdOrigin,
  extractObjectValues,
  mapGetters,
  mapActions,
} from '../helpers/mainHelpers.js';

/* Composables */
import { useScreenWidth } from '../composables/useScreenWidth.js';

/* Icons */
import PinLocation from '../../public/pin-location.svg';
import PinSelectedLake from '../../public/pin-selected_lake.svg';
import PinUnselectedLake from '../../public/pin-unselected_lake.svg';

/* Components */
import MapSettings from '../containers/MapSettings.vue';
import SearchBtn from './SearchBtn.vue';
import GoogleMapsMutantLayer from './GoogleMapsMutantLayer.vue';

/* Inject */
const showGarminMaps = inject('showGarminMaps');
const isHeatmap = inject('isHeatmap');
const updatesTimeframes = inject('updatesTimeframes');
const selectedPeriod = inject('selectedPeriod');

/* Store - Getters */
const {
  getMapCenter: mapCenter,
  getPolygon: polygon,
  getZoom: currentZoomLevel,
  getDrawerMode: drawerMode,
  getGoogleMapsApiKey: googleMapsApiKey,
  getIsSearched: isSearched,
  getActiveLake: activeLake,
  getLakes: lakes,
  getLatLng: latLng,
  getLocale: locale,
  getTranslations: translations,
  getChartType: chartType,
  getChartDepth: depthUnit,
  getSeabedAreasState: seabedAreasState,
  getSafetyDepth: safetyDepth,
  getLocationMarker: locationMarker,
  getShowLocationMarker: showLocationMarker,
  getShowMapSettings: showMapSettings,
  getIsMapSettingsOpen: isMapSettingsOpen,
  getGarminTileToken: garminTileToken,
  getNavionicsWebApiServiceBase: navionicsServiceBase,
  getNavionicsTileToken: navionicsTileToken,
  getNavionicsWebApiTokens: navionicsTokens,
  getContentfulEnvSettings: contentfulEnvSettings,
  getMapHeight: mapHeight,
} = mapGetters();

/* Store - Actions */
const {
  setLoading,
  updateMapInfo,
  updateUrlForMap,
  setPartNumber,
  setZoom: setCurrentZoom,
  setIsSearched,
  setDrawerMode,
  setShowInstructions,
  setSearchQuery,
  setFormattedAddress,
  searchMap,
  setActiveLake,
  setShowLakeInfo,
  setLocationMarker,
  setShowLocationMarker,
  fetchGarminTileToken,
  fetchNavionicsTileToken,
  setMapHeight,
  setDisplayMobileSearch,
  setDisplayMobileResults,
} = mapActions();

/* State */
const state = ref({
  // map info
  map: null,
  sharedLat: null,
  sharedLong: null,

  // google map info
  google: null,
  displayGoogleMap: true,
  defaultMapLanguage: 'en',
  defaultMapRegion: 'US',

  // chart view
  displayChartView: false,
  garminChartViewerUrls: contentfulEnvSettings.value?.ITFE_MARINE_MAPS_CHART_VIEWER_URLS,
  defaultChartViewerUrl: ['mcv-stage.marine.garmin.com'],

  // navionics
  displayNavionicsMapView: computed(() => !showGarminMaps.value && !isHeatmap.value),
  navionicsTilesSubdomainUrls: contentfulEnvSettings.value?.ITFE_MARINE_MAPS_NAVIONICS_TILES_SUBDOMAIN_URLS,
  defaultNavionicsTilesSubdomainUrl: ['tile1.navionics.com'],

  // heatmap view
  displayHeatmap: isHeatmap.value,

  // Zoom
  zoom: 7,
  minZoomLevel: 3,
  maxZoomLevel: 18,
  // Limits vertical map panning beyond tile image range but allows infinite horizontal panning
  maxBounds: [[[90, -Infinity], [-90, Infinity]]],
  maxBoundsViscosity: 1,

  // Search Map
  searchInMapIsVisible: false,
  searchThisArea: false,
  searchAsMapMoves: false,

  // polygon
  showPolygon: false,

  // map tile tokens intervals
  garminTileTokenInterval: false,
  navionicsTileTokenInterval: false,

  // SVGs
  pinLocationPath: PinLocation,
  pinSelectedLakePath: PinSelectedLake,
  pinUnselectedLakePath: PinUnselectedLake,

  // Dev env
  isDevEnv: !isProdOrigin(window?.origin),
});

// Create a ref for mapViewer
const mapViewer = ref(null);
const navionicsTileLayer = ref(null);

// Initialize composables
const { isMobile } = useScreenWidth();

// Normalize depth units for use with Garmin and Navionics tile APIs
const navionicsDepthUnits = Object.freeze({
  m: 1,
  ft: 2,
  fath: 3,
});

const selectedTimeframe = computed(() => updatesTimeframes[selectedPeriod.value]);

const normalizedDepthUnit = computed(() => (showGarminMaps.value ? depthUnit.value : navionicsDepthUnits[depthUnit.value]));

const showSeabedAreas = computed(() => (seabedAreasState.value === 'show'));

/* eslint-disable max-len */
/* Get Garmin Chart Viewer Urls via Contentful */
const garminChartViewerSubdomainUrls = computed(() => extractObjectValues(state.value?.garminChartViewerUrls ?? state.value?.defaultChartViewerUrl));

/* Get Navionics Tile Subdomain Urls via Contentful */
const navionicsTilesSubdomainUrls = computed(() => extractObjectValues(state.value?.navionicsTilesSubdomainUrls ?? state.value?.defaultNavionicsTilesSubdomainUrl));

const garminChartViewerTilesUrl = computed(() => `https://{s}/api/tile/{z}/{x}/{y}.png?units=${normalizedDepthUnit.value}&charttype=${chartType.value}&safetydepth=${safetyDepth.value}&seabedAreas=${showSeabedAreas.value}&token=${garminTileToken.value}`);

const chartTypeTheme = computed(() => (chartType.value === 'nautical' ? 0 : 1));

const prodNavionicsTileHostname = computed(() => `https://${navionicsTilesSubdomainUrls.value[Math.floor(Math.random() * navionicsTilesSubdomainUrls.value.length)]}`);

const navionicsMapTileUrl = computed(() => `${state.value.isDevEnv ? navionicsServiceBase.value : prodNavionicsTileHostname.value}/viewer/api/v1/tile/{z}/{x}/{y}`);

const navionicsHeatmapDotsTilesUrl = computed(() => `https://backend-heatmap.navionics.com/charts/updates/heatmap/navionics/get_heatmap_tile/?source=navionics&level=3&start_color=32,157,244&end_color=245,245,245&color_range=5&z={z}&x={x}&y={y}&to=${selectedTimeframe.value?.to}&from=${selectedTimeframe.value?.from}&ugc=false`);

const navionicsDarkMapTilesUrl = computed(() => `https://{s}/tile/{z}/{x}/{y}?LAYERS=config_1_1_0&TRANSPARENT=FALSE&theme=3&navtoken=${navionicsTileToken.value}&ugc=false`);
/* eslint-enable max-len */

// reduce height of map only when the drawer is half way
// and the user has already searched something
const isMiddleDrawer = computed(() => drawerMode.value === 'middle' && isSearched.value);

const mapLanguage = computed(() => {
  const localeArray = locale.value.split('-');
  return localeArray[0] || state.value.defaultMapLanguage;
});

const mapRegion = computed(() => {
  const localeArray = locale.value.split('-');
  return localeArray[1] || state.value.defaultMapRegion;
});

const middleDrawerMapClass = computed(() => (isMiddleDrawer.value ? 'map-viewer__wrap--middle' : ''));

const getPinIcon = computed(() => new Icon({
  iconUrl: state.value.pinLocationPath,
  iconSize: [39, 48],
  iconAnchor: [18, 38],
}));

const clusterKey = computed(() => {
  // This forces the marker cluster to re-render when we change from zoom level 1-9 to higher
  if (currentZoomLevel.value < 6) {
    return 'no-pins';
  }

  if (currentZoomLevel.value < 8) {
    return 'low-pins';
  }

  if (currentZoomLevel.value < 10) {
    return 'medium-pins';
  }

  return 'high-pins';
});

const getClusterOptions = computed(() => {
  let radiusForZoom = 80;

  if (currentZoomLevel.value < 10) {
    radiusForZoom = 150;
  }

  if (currentZoomLevel.value < 8) {
    radiusForZoom = 300;
  }

  if (currentZoomLevel.value < 6) {
    radiusForZoom = 600;
  }

  return {
    // When below zoom level 10 we force the clusterer to use a bigger radius so no pins show
    maxClusterRadius: currentZoomLevel.value < 10 ? radiusForZoom : 10,
    // Remove the 'blue' polygon coverage from the clusters on hover
    showCoverageOnHover: false,
    // Sets iconsize to null so we can control
    // cluster width/height via CSS
    iconCreateFunction(cluster) {
      const childCount = cluster.getChildCount();
      let c = ' marker-cluster-';
      if (childCount < 10) {
        c += 'small';
      } else if (childCount < 100) {
        c += 'medium';
      } else {
        c += 'large';
      }

      return new L.DivIcon({
        html: `<div><span>${childCount}</span></div>`,
        className: `marker-cluster${c}`,
        iconSize: null,
      });
    },
  };
});

const customAttribution = computed(() => {
  /* eslint-disable max-len */
  const acknowledgements = translations.value.ITFE_MARINE_MAPS_ACKNOWLEDGEMENTS
    ? `<a target="_blank" href="${translations.value?.ITFE_MARINE_MAPS_ACKNOWLEDGEMENTS_URL}">${translations.value.ITFE_MARINE_MAPS_ACKNOWLEDGEMENTS}</a> |`
    : '';
  /* eslint-enable max-len */
  const notForNavigation = translations.value.ITFE_MARINE_MAPS_NOT_FOR_NAVIGATION
    ? `${translations.value.ITFE_MARINE_MAPS_NOT_FOR_NAVIGATION} |`
    : '';
  const leaflet = '<a target="_blank" href="https://leafletjs.com">Leaflet</a>';

  if (currentZoomLevel.value >= 7 || isHeatmap.value) {
    return `${acknowledgements} ${notForNavigation} ${leaflet}`;
  }
  return `${leaflet}`;
});

/**
 * Options for configuring the Google Maps satellite layer.
 * @type {object}
 * @property {number} maxZoom       - The maximum zoom level for the layer.
 * @property {string} type          - The type of map layer ('satellite' in this case).
 * @property {number} maxNativeZoom - The maximum native zoom level supported by the layer.
 */
const googleMutantLayerOptions = {
  maxZoom: 21,
  type: 'hybrid',
  maxNativeZoom: 21,
  className: 'google-map-tiles',
};

const panToLatLng = () => {
  mapViewer.value.leafletObject.panTo(latLng.value);
};

const fitPolygon = (coordinates) => {
  const flatCoordinates = coordinates.flat(Infinity);
  let minLat = 90;
  let maxLat = -90;
  let minLng = 180;
  let maxLng = -180;

  // find min and max latitude and longitude
  flatCoordinates.forEach((el, i) => {
    if (i % 2 === 0) {
      if (el < minLat) minLat = el;
      if (el > maxLat) maxLat = el;
    } else {
      if (el < minLng) minLng = el;
      if (el > maxLng) maxLng = el;
    }
  });

  // Create bounds and fit the map view
  // eslint-disable-next-line new-cap
  const bounds = new L.latLngBounds(
    [minLat, maxLng],
    [maxLat, minLng],
  );
  mapViewer.value.leafletObject.fitBounds(bounds, { padding: [50, 50] });
  state.value.showPolygon = true;
};

let handleMarkerClick = (e, lake) => {
  setDisplayMobileSearch(false);
  setDisplayMobileResults(true);

  if (activeLake.value?.id === lake?.id) {
    setActiveLake(null);
    setShowLakeInfo(false);
  } else {
    setActiveLake(lake);
    setShowLakeInfo(activeLake.value?.id === lake?.id);
  }
};

const computeMapHeight = (windowWidth) => {
  const getDynamicMapHeight = () => {
    if (windowWidth >= 768) {
      const desktopDrawerHeight = document.querySelector('.drawer__results') && document.querySelector('.drawer__results').offsetHeight;
      return desktopDrawerHeight;
    }

    if (isMapSettingsOpen.value) {
      const headerHeight = document.querySelector('.gh__header') ? document.querySelector('.gh__header').offsetHeight : 0;
      const headerBanner = document.querySelector('.m__header') ? document.querySelector('.m__header').offsetHeight : 0;
      return window.innerHeight - headerHeight - headerBanner;
    }

    return 387;
  };

  setMapHeight(getDynamicMapHeight());
};

const setMapHeightResize = () => {
  const debounceHandler = () => {
    computeMapHeight(window.innerWidth);
  };
  window.onresize = debounce(debounceHandler, 300);
};

const handleMapStyling = () => {
  /*
   * We need this to add a class to the map container ->
   * In Leaflet Vue3, we cannot add it directly to the map container by using
   * Vue syntax, so we need to do it through refs
   */
  const mapRoot = mapViewer.value.root;
  if (mapRoot) {
    // set default height for map container -> needed for mobile styling
    mapRoot.style.height = '';
    // add class to map container -> needed for mobile styling
    mapRoot.classList.add(!isMiddleDrawer.value ? 'map-viewer__wrap--middle' : '');
  }
};

const markerHtmlAtrributes = () => {
  const markerIcons = document.querySelectorAll('.leaflet-marker-icon');
  if (!markerIcons) return;

  markerIcons.forEach((marker) => {
    marker.removeAttribute('tabindex');
    marker.setAttribute('alt', translations.value.ITFE_MARINE_MAPS_MARKER_ICON);
    marker.setAttribute('title', translations.value.ITFE_MARINE_MAPS_MARKER_ICON);
  });
};

const zoomHtmlAtrributes = () => {
  const zoomInElement = document.querySelectorAll('.leaflet-control-zoom-in');
  const zoomOutElement = document.querySelectorAll('.leaflet-control-zoom-out');
  if (!zoomInElement || !zoomOutElement) return;

  zoomInElement.forEach((zoomIn) => {
    zoomIn.setAttribute('aria-label', translations.value.ITFE_MARINE_MAPS_ZOOM_IN_BUTTON);
    zoomIn.setAttribute('title', translations.value.ITFE_MARINE_MAPS_ZOOM_IN_BUTTON);
    zoomIn.setAttribute('alt', translations.value.ITFE_MARINE_MAPS_ZOOM_IN_BUTTON);
  });

  zoomOutElement.forEach((zoomOut) => {
    zoomOut.setAttribute('aria-label', translations.value.ITFE_MARINE_MAPS_ZOOM_OUT_BUTTON);
    zoomOut.setAttribute('title', translations.value.ITFE_MARINE_MAPS_ZOOM_OUT_BUTTON);
    zoomOut.setAttribute('alt', translations.value.ITFE_MARINE_MAPS_ZOOM_OUT_BUTTON);
  });
};

const logCurrentZoomLevel = () => {
  // eslint-disable-next-line no-console
  if (state.value.isDevEnv) console.log(`Current Zoom Level: ${currentZoomLevel.value}`);
};

const geolocationSearch = (lat, long) => new Promise((resolve) => {
  const geocoder = new state.value.google.maps.Geocoder();
  // eslint-disable-next-line no-shadow
  const latLng = { lat: parseFloat(lat), lng: parseFloat(long) };
  geocoder.geocode({ location: latLng }, (results, status) => {
    if (status === 'OK') {
      resolve(results);
    } else {
      resolve(null);
    }
  });
});

const reverseSearch = (lat, lng) => {
  setIsSearched(true);
  setPartNumber(null);
  // not using searchbar so clearing it outline
  setSearchQuery('');
  geolocationSearch(lat, lng).then((searchResults) => {
    if (searchResults) {
      let index = 1;
      if (searchResults.length < 2) {
        index = 0;
      }
      if (searchResults[index]) {
        setFormattedAddress(searchResults[index].formatted_address);
        setDrawerMode('middle');
      }
    } else {
      setFormattedAddress(null);
    }
    // don't change zoom level when user clicks on map, only bounds
    updateMapInfo({
      lat,
      lng,
      bounds: null,
    });
    panToLatLng();
  });
};

const setDefaultsOnload = () => {
  // if lat long are set, update the map with it
  let latValue;
  let longValue;
  if (getLatLngFromURL()) {
    [latValue, longValue] = getLatLngFromURL() || [];
  }

  // Hide red pin on initial load of map
  // Only show on map click or search
  setShowLocationMarker(false);

  // lat long in query params
  if (latValue && longValue) {
    // show results if coordinates are in the query
    setIsSearched(true);
    setDrawerMode('middle');
    setLoading(false);
    reverseSearch(latValue, longValue);
    // If users is sharing location, zoom to level 10
    if (navigator.geolocation) {
      mapViewer.value.leafletObject.setZoom(10);

      // Set red pin and user location lat/long
      setLocationMarker({ lat: latValue, lng: longValue });
    }
  } else if (navigator.geolocation) {
    // search based on current location
    setLoading(true);
    setShowInstructions(true);
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLoading(false);
        setShowInstructions(false);
        const latitude = position?.coords?.latitude;
        const longitude = position?.coords?.longitude;
        state.value.sharedLat = latitude;
        state.value.sharedLong = longitude;
        reverseSearch(latitude, longitude);
        // If users is sharing location, zoom to level 10
        mapViewer.value.leafletObject.setZoom(10);
        // Set red pin and user location lat/long
        setLocationMarker({ lat: latitude, lng: longitude });
        // show results if user shares location
        setIsSearched(true);
        setDrawerMode('middle');
      },
      /* second anonymous function handles errors -- e.g. PERMISSION_DENIED */
      () => {
        if (latValue && longValue) {
          setLoading(true);
          reverseSearch(latValue, longValue);
          // Set red pin and user location lat/long
          setLocationMarker({ lat: latValue, lng: longValue });
        } else {
          // If no geolocation permission search for default products
          setIsSearched(false);
          setLoading(false);
          searchMap();
          panToLatLng();
          // Set red pin and user location lat/long
          setLocationMarker({
            lat: mapCenter.value[0],
            lng: mapCenter.value[1],
          });
          updateUrlForMap();
        }
      },
      /* third param is options passed to geolocation.getCurrentPosition */
      {
        enableHighAccuracy: true,
      },
    );
  } else {
    // If browser doesn't support geolocation display default products
    searchMap();
    panToLatLng();
    // Set red pin and user location lat/long
    setLocationMarker({
      lat: mapCenter.value[0],
      lng: mapCenter.value[1],
    });
    updateUrlForMap();
  }
};

const loadMap = () => {
  setDefaultsOnload();
  setActiveLake(null);
};

const handleGoogleMutantLayer = (leafletMap, google) => {
  state.value.google = google;

  loadMap();
};

const handleClick = (e) => {
  // Reset location marker
  setLocationMarker(null);

  // Reset lake details
  setActiveLake(null);

  if (e?.latlng?.lat && e?.latlng?.lng) {
    reverseSearch(e.latlng.lat, e.latlng.lng);
  }

  if (isMobile.value) {
    setDisplayMobileSearch(false);
    setDisplayMobileResults(true);
  }

  state.value.showPolygon = false;
  // Show red pin on map click
  setShowLocationMarker(true);
};

const getLakeIcon = (lake) => {
  const currentActiveLake = activeLake.value?.id === lake?.id;
  return new Icon({
    iconUrl: currentActiveLake ? state.value.pinSelectedLakePath : state.value.pinUnselectedLakePath,
    iconSize: currentActiveLake ? [41, 64] : [29, 38],
    iconAnchor: currentActiveLake ? [23, 57] : [18, 38],
  });
};

const getGoogleAttributonSelector = () => {
  // This selects all attribution controls except the first one
  const attributionClassName = '.leaflet-control-attribution';
  return document.querySelector(`${attributionClassName} ~ ${attributionClassName}`);
};

const hideGoogleAttribution = () => {
  if (!getGoogleAttributonSelector()) return;
  getGoogleAttributonSelector().style.display = 'none';
};

const showGoogleAttribution = () => {
  if (!getGoogleAttributonSelector()) return;
  getGoogleAttributonSelector().style.display = 'block';
};

const setMapView = () => {
  const updatedZoomLevel = mapViewer.value.leafletObject.getZoom();

  // updated:zoom event fires even when panning the map, not just zooming
  // this check prevents unnecessary updates of which layer we display
  if (updatedZoomLevel !== currentZoomLevel.value) {
    if (!isHeatmap.value) {
      if (updatedZoomLevel >= 7) {
        state.value.displayChartView = true;
        state.value.displayGoogleMap = false;
        hideGoogleAttribution();
      } else {
        state.value.displayChartView = false;
        state.value.displayGoogleMap = true;
        showGoogleAttribution();
      }
    }
  }
  setCurrentZoom(updatedZoomLevel);
};

const handleUpdatedZoom = () => {
  setMapView();
};

const initNavionicsMapViewTiles = () => {
  L.TileLayer.CustomHeader = L.TileLayer.extend({
    createTile(coords, done) {
      const tile = document.createElement('img');
      const {
        access_token: accessToken,
        configuration_token: configToken,
      } = navionicsTokens.value;

      let url = this.getTileUrl(coords);
      // eslint-disable-next-line max-len
      url += `?config=${configToken}&transparent=false&ugc=false&layer=${chartTypeTheme.value}&du=${normalizedDepthUnit.value}&sd=${safetyDepth.value}&sa=${showSeabedAreas.value}`;

      fetch(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Received ${response.status}: ${response.statusText}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const objectUrl = URL.createObjectURL(blob);
          tile.onload = () => URL.revokeObjectURL(objectUrl);
          tile.src = objectUrl;
          done(null, tile);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Unable to fetch Navionics Map Tiles:', error);
          done(error, null);
        });

      return tile;
    },
  });
};

const loadNavionicsMap = () => {
  if (!mapViewer.value?.leafletObject) {
    return;
  }

  if (navionicsTileLayer.value) {
    mapViewer.value.leafletObject.removeLayer(navionicsTileLayer.value);
  }

  // add Navionics layer if enabled
  if (state.value.displayNavionicsMapView) {
    initNavionicsMapViewTiles();
    navionicsTileLayer.value = new L.TileLayer.CustomHeader(navionicsMapTileUrl.value, {
      subdomains: state.value.navionicsTilesSubdomainUrls,
    });
    navionicsTileLayer.value.addTo(mapViewer.value.leafletObject);
  }
};

/* Lifecycle Hooks */
onMounted(() => {
  computeMapHeight(window.innerWidth);
  setMapHeightResize();
  handleMapStyling();
});

onUpdated(() => {
  markerHtmlAtrributes();
  zoomHtmlAtrributes();
  logCurrentZoomLevel();
});

onBeforeMount(() => {
  // Get initial Garmin tile token on load
  fetchGarminTileToken();
  // Get initial Navionics tile token on load
  fetchNavionicsTileToken();
  handleMarkerClick = debounce(handleMarkerClick, 300);

  // Get Garmin tile token every ~4.5 minutes
  state.value.garminTileTokenInterval = setInterval(async () => {
    await fetchGarminTileToken();
  }, garminTileTokenIntervalDelay);

  // Get Navionics tile token every ~4.5 minutes
  state.value.navionicsTileTokenInterval = setInterval(async () => {
    await fetchNavionicsTileToken();
  }, navionicsTileTokenIntervalDelay);
});

/* Watch */
watch(isHeatmap, (newValue) => {
  if (newValue) {
    state.value.displayChartView = false;
    state.value.displayGoogleMap = false;
    state.value.displayHeatmap = true;
    hideGoogleAttribution();
  } else {
    state.value.displayHeatmap = false;
    if (currentZoomLevel.value >= 7) {
      state.value.displayChartView = true;
      state.value.displayGoogleMap = false;
      hideGoogleAttribution();
    } else {
      state.value.displayChartView = false;
      state.value.displayGoogleMap = true;
      showGoogleAttribution();
    }
  }
});

// Load Navionics map tiles when map object is loaded
watch(
  () => mapViewer.value?.leafletObject,
  () => loadNavionicsMap(),
  { immediate: true },
);

// Load Navionics map tiles when 'Another Brand' Map View is selected
watch(
  () => state.value.displayNavionicsMapView,
  () => loadNavionicsMap(),
);

watch(latLng, () => {
  panToLatLng();
});

watch(polygon, (newPolygon) => {
  if (newPolygon) {
    const { coordinates } = newPolygon;
    fitPolygon(coordinates);
  }
  state.value.showPolygon = !!newPolygon;
});

/* Handle Map Settings -> Mobile vs Desktop */
const mapSettingsOpenPosition = computed(() => (isMobile.value ? 'top' : 'bottom'));

/* Leaflet Control Position -> Mobile vs Desktop */
const leafletControlPosition = computed(() => (isMobile.value ? 'bottomright' : 'topleft'));

const mapViewType = computed(() => (isHeatmap.value ? 'heatmap' : 'map'));

const showGarminTileLayer = computed(() => state.value.displayChartView && showGarminMaps.value);
</script>

<template>
  <div>
    <div
      id="map-viewer__wrap"
      :style="{ height: `${mapHeight}px` }"
      :class="['map-viewer__wrap', middleDrawerMapClass]"
      :data-chart-viewer-map="!state.displayGoogleMap"
      :data-google-map="state.displayGoogleMap"
    >
      <l-map
        ref="mapViewer"
        v-model:zoom="state.zoom"
        :use-global-leaflet="true"
        :zoom="state.currentZoomLevel"
        :min-zoom="state.minZoomLevel"
        :max-zoom="state.maxZoomLevel"
        :max-bounds="state.maxBounds"
        :max-bounds-viscosity="state.maxBoundsViscosity"
        :center="mapCenter"
        :options="{
          zoomControl: false,
          doubleClickZoom: false,
          worldCopyJump: true,
          attributionControl: false,
        }"
        @click="handleClick"
        @update:zoom="handleUpdatedZoom"
      >
        <GoogleMapsMutantLayer
          v-show="state.displayGoogleMap"
          :apikey="googleMapsApiKey"
          :lang="mapLanguage"
          :region="mapRegion"
          :options="googleMutantLayerOptions"
          @ready="handleGoogleMutantLayer"
        />
        <l-tile-layer
          v-if="showGarminTileLayer"
          :url="garminChartViewerTilesUrl"
          :options="{
            crossOrigin: 'anonymous',
            className: 'chart-view-tiles',
          }"
          :subdomains="garminChartViewerSubdomainUrls"
        />
        <l-tile-layer
          v-if="state.displayHeatmap"
          :url="navionicsHeatmapDotsTilesUrl"
          :options="{
            crossOrigin: 'anonymous',
            className: 'blue-dot-tiles',
          }"
        />
        <l-tile-layer
          v-if="state.displayHeatmap"
          :url="navionicsDarkMapTilesUrl"
          :options="{
            crossOrigin: 'anonymous',
            className: 'dark-map-tiles',
          }"
          :subdomains="navionicsTilesSubdomainUrls"
        />
        <l-control-attribution
          position="bottomright"
          :prefix="customAttribution"
        />
        <l-marker
          v-if="locationMarker && showLocationMarker"
          :icon="getPinIcon"
          :lat-lng="locationMarker"
        />
        <l-marker-cluster-group
          :key="clusterKey"
          v-bind="getClusterOptions"
        >
          <l-marker
            v-for="lake in lakes"
            :key="lake.id"
            :lat-lng="[lake.center_lat, lake.center_lon]"
            :icon="getLakeIcon(lake)"
            @click="handleMarkerClick($event, lake)"
          />
        </l-marker-cluster-group>
        <l-polygon
          v-if="state.showPolygon"
          :lat-lngs="polygon?.coordinates"
          :color="$colors.green"
          :fill-color="$colors.white"
          :weight="5"
          :fill-opacity="0.5"
          :options="{
            className: 'coverage-polygon',
          }"
        />
        <l-control-zoom position="bottomright" />
        <l-control :position="leafletControlPosition">
          <div class="map-viewer__actions">
            <SearchBtn />
            <MapSettings
              v-if="showMapSettings"
              class="map-viewer__wrap__settings"
              :open-position="mapSettingsOpenPosition"
              :translations="translations"
              :depth-unit="depthUnit"
              :safety-depth="safetyDepth"
              :seabed-areas-state="seabedAreasState"
              :map-view-type="mapViewType"
              :chart-type="chartType"
              @trigger-map-change="loadNavionicsMap"
            />
          </div>
        </l-control>
      </l-map>
    </div>
  </div>
</template>

<style lang="scss" scoped>
/* stylelint-disable declaration-no-important */
.map-viewer__actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.map-viewer__wrap {
  width: 100vw;
  position: relative;
  background-color: $color-gray-95;

  @include breakpoint('sm') {
    width: calc(100% - #{$drawerWidthTablet});
    left: #{$drawerWidthTablet};
  }
  @include breakpoint('md') {
    width: calc(100% - #{$drawerWidthDesktop});
    left: #{$drawerWidthDesktop};
  }

  // handle middle drawer when on mobile + desktop
  &--middle {
    height: 50%;
    @include breakpoint('sm') {
      height: 100%;
    }
  }

  // ADJUSTS MAP HEIGHT ON MOBILE
  .leaflet-container {
    height: 100%;
  }
}

/**
 * When tabbing through Leaflet Map, focus outline flashes but does not stay lit up
 * https://github.com/Leaflet/Leaflet/issues/6986
 */

.leaflet-container :focus { // Fallback for some browsers that don't support `revert`.
  outline: revert !important;
}

.leaflet-right .leaflet-control {
  margin-right: 1rem; // 16px
}

.leaflet-control-container {

  .leaflet-control-settings {
    margin-right: 0.5rem !important;
  }

  .leaflet-control-attribution {
    background-color: $color-gray-95;
    font-size: 11px;

    div {
      opacity: inherit !important;
    }
  }
}

// Hides Google Map attribution when it is not meant to be actively displayed
:deep([data-google-map = 'false'] .leaflet-control-attribution ~ .leaflet-control-attribution) {
  display: none;
}

// Hides Google Map logo when it is not meant to be actively displayed
:deep([data-google-map = 'false'] a[href^="http://maps.google.com/maps"]),
:deep([data-google-map = 'false'] a[href^="https://maps.google.com/maps"]),
:deep([data-google-map = 'false'] a[href^="https://www.google.com/maps"]) {
  display: none !important;
}

// Ensures Google maps renders over chartviewer and navionics chart tiles when active
:deep([data-google-map = 'true'] .google-map-tiles) {
  z-index: 5 !important;
}

// Hides Google Map tiles when it is not meant to be actively displayed
:deep([data-google-map = 'false'] .google-map-tiles) {
  display: none;
}

// Ensures Dark Map and Blue Dot tiles for Heatmap renders over google maps when active
:deep(.dark-map-tiles) {
  z-index: 10 !important;
}

:deep(.blue-dot-tiles) {
  z-index: 15 !important;
}
/* stylelint-enable declaration-no-important */
</style>

<style lang="scss">
/* stylelint-disable declaration-no-important */
.marker-cluster-small {
  background-color: $primary-purple-highlight;
  color: $color-white;

  div {
    background-color: $primary-purple-light;
    border: 2px solid $color-white;
  }
}

.marker-cluster-medium {
  background-color: $primary-purple-highlight;
  color: $color-white;

  div {
    background-color: $primary-purple;
    border: 2px solid $color-white;
  }
}

.marker-cluster-large {
  background-color: $primary-purple-highlight;
  color: $color-white;

  div {
    background-color: $primary-purple-dark;
    border: 2px solid $color-white;
  }
}

/* !important needed to override inline styles set by leaflet */
.marker-cluster {
  background-clip: padding-box;
  border-radius: 50%;
  width: 60px !important;
  height: 60px !important;
  margin-left: -30px !important;
  margin-top: -30px !important;

  @include breakpoint('sm') {
    width: 40px !important;
    height: 40px !important;
    margin-left: -20px !important;
    margin-top: -20px !important;
  }

  div {
    width: 50px;
    height: 50px;
    margin-left: 5px;
    margin-top: 5px;
    text-align: center;
    border-radius: 50%;
    font: 12px 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;

    @include breakpoint('sm') {
      width: 30px;
      height: 30px;
      margin-left: 5px;
      margin-top: 5px;
    }
  }

  span {
    @include font-primary-weight-bold();
    line-height: 45px;
    font-size: 18px;

    @include breakpoint('sm') {
      line-height: 25px;
      font-size: 11px;
    }
  }
}

.leaflet-marker-icon:not(.marker-cluster) {
  z-index: 10 !important;
}

.leaflet-cluster-anim .leaflet-marker-icon,
.leaflet-cluster-anim .leaflet-marker-shadow {
  /* stylelint-disable declaration-block-no-duplicate-properties  */
  transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
  transition: transform 0.3s ease-out, opacity 0.3s ease-in;
}

.leaflet-cluster-spider-leg {
  /* stroke-dashoffset (duration and function) should match with leaflet-marker-icon transform in order to track it exactly */
  transition: -o-stroke-dashoffset 0.3s ease-out, -o-stroke-opacity 0.3s ease-in;
  transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
}

/* Hide Leaflet zoom control on mobile */
.leaflet-control-zoom {
  display: none;

  @include breakpoint('sm') {
    display: block;
  }
}

/* Style Leaflet zoom control */
.leaflet-touch .leaflet-bar {
  border: none !important;

  .leaflet-disabled {
    cursor: default !important;
    /* stylelint-disable-next-line color-no-hex */
    background-color: #f4f4f4 !important;
    /* stylelint-disable-next-line color-no-hex */
    color: #bbb !important;
  }

  a {
    line-height: 25px !important;
    color: $color-black !important;
    border: 1px solid $color-black !important;
    border-bottom: 0 !important;
    box-shadow: none !important;
    border-radius: 0 !important;

    &:last-child {
      border-bottom: 1px $color-black solid !important;
    }

    &:first-child,
    &:last-child {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }
}
/* stylelint-enable declaration-no-important */
</style>
