<script setup>
/* Imports */
import {
  inject,
} from 'vue';

/* Props */
const props = defineProps({
  translations: {
    type: Object,
    default: () => ({}),
  },
  seabedAreasState: {
    type: String,
    default: '',
  },
});

/* Inject */
const isHeatmap = inject('isHeatmap');

/* Emits */
const emit = defineEmits(['changeSeabedAreasState']);

/* Methods */
const changeSeabedAreasState = (event) => {
  emit('changeSeabedAreasState', event?.target?.value);
};

const headingDataTestId = (label) => ({
  host: `g__heading-host-${label}`,
  heading: `g__heading-${label}`,
});

const radioDataTestId = (label) => ({
  host: `g__radio-host-${label}`,
  labelText: `g__radio-label-${label}`,
});
</script>

<template>
  <div
    v-if="!isHeatmap"
    class="seabed-areas"
    :dataTestId.prop="headingDataTestId('seabed-areas')"
  >
    <g-heading
      class="seabed-areas__heading"
      data-testid="seabed-areas__heading"
      :content="props.translations?.ITFE_MARINE_MAPS_SEABED_AREAS"
      size="4"
    />
    <g-radio
      :label="props.translations?.ITFE_MARINE_MAPS_SHOW"
      :dataTestId.prop="radioDataTestId('show-seabed-areas')"
    >
      <input
        name="seabedAreas"
        type="radio"
        value="show"
        :checked="props.seabedAreasState === 'show'"
        @change="changeSeabedAreasState($event)"
      >
    </g-radio>
    <g-radio
      :label="props.translations?.ITFE_MARINE_MAPS_HIDE"
      :dataTestId.prop="radioDataTestId('hide-seabed-areas')"
    >
      <input
        name="seabedAreas"
        type="radio"
        value="hide"
        :checked="props.seabedAreasState === 'hide'"
        @change="changeSeabedAreasState($event)"
      >
    </g-radio>
  </div>
</template>

<style lang="scss" scoped>
@include map-settings('seabed-areas');
</style>
